<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-header :bet="stateData.currentSpinResult.bet"
                                 :lines="stateData.currentSpinResult.linesCount"
                                 :stake="stateData.currentSpinResult.stake"
            />
        </template>

        <template #workspace>
            <default-slot-workspace :layout="stateData.currentSpinResult.layout"
                                    :symbols-path="symbolsPath"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
    import WorkspaceWrapper from "../Common/WorkspaceWrapper";
    import DefaultSlotHeader from "./Common/Header/DefaultSlotHeader";
    import DefaultSlotWorkspace from "./Common/DefaultSlotWorkspace";

    export default {
        props: {
            stateData: {
                type: Object,
                required: true,
                default() {
                    return {
                        currentSpinResult: {
                            balanceBefore: null,
                            bet: null,
                            linesCount: null,
                            stake: null,
                            layout: null,
                        }
                    }
                }
            }
        },
        data() {
            return {
                symbolsPath: 'superduppercherry'
            }
        },
        components: {
            WorkspaceWrapper,
            DefaultSlotHeader,
            DefaultSlotWorkspace
        }
    }
</script>

<style scoped>

</style>
